<template>
  <div class="pt-4 pb-5">
    <h1 class="dark-text font-weight-semi-bold fz-28 mb-4 d-none d-md-block">Active Calls</h1>
    <h1 class="dark-text font-weight-semi-bold fz-25 mb-4 d-md-none px-5">Active Calls</h1>

    <v-card
        :style="loading ? 'min-height:4px' : ''"
        class="pa-0 overflow-hidden"
    >
      <v-data-table
          :headers="headers"
          :items="liveCalls"
          :loading="loading"
          :items-per-page="take"
          hide-default-footer
          disable-sort
      >
        <template
            v-for="header in headers.filter((header) =>
            header.hasOwnProperty('formatter')
          )"
            v-slot:[`item.${header.value}`]="{ header, value }"
        >
          {{ header.formatter(value) }}
        </template>
        <template v-slot:[`item.direction`]="{ item }">
          <span class="text-uppercase">{{ item.direction }}</span>
        </template>
        <template v-slot:[`item.from`]="{ item }">
          <span class="text-uppercase">{{ item.direction === 'out' ? item.ani : item.dnis }}</span>
        </template>
        <template v-slot:[`item.to`]="{ item }">
          <span class="text-uppercase">{{ item.direction === 'out' ? item.dnis : item.ani }}</span>
        </template>
        <template v-slot:[`item.state`]="{ item }">
          <span class="text-uppercase">{{ stateFormatter(item.state) }}</span>
        </template>
        <template v-slot:[`item.duration`]="{ item }">{{ item.duration }}s</template>
      </v-data-table>
    </v-card>
    <div v-if="liveCalls.length > take" class="d-flex justify-center white pt-3 pb-5">
      <v-btn
          text
          tile
          min-width="100px"
          class="px-0"
          max-width="100px"
          width="100px"
          @click="loadMore(page)"
      >
        Load More
      </v-btn>
    </div>
  </div>
</template>

<script>
import { mapState, mapActions, mapMutations } from 'vuex'
import moment from 'moment/moment'

export default {
  name: 'ActiveCalls',
  data: () => ({
    avatar: 'https://picsum.photos/200',
    calls: {
      total: 0,
      items: []
    },
    loading: false,
    page: 1,
    take: 100,
    timer: null,
    headers: [
      {
        text: 'Direction',
        value: 'direction',
        sortable: false,
        class: ['text-caption', 'greyBuoyant'],
        cellClass: 'greyBuoyant'
      },
      {
        text: 'From',
        value: 'from',
        sortable: false,
        class: ['text-caption', 'greyBuoyant'],
        cellClass: 'greyBuoyant'
      },
      {
        text: 'To',
        value: 'to',
        sortable: false,
        class: ['text-caption', 'greyBuoyant'],
        cellClass: 'greyBuoyant'
      },
      {
        text: 'Start',
        align: 'start_on',
        value: 'created',
        formatter: (x) => (x ? moment(x).format('YYYY-MM-DD HH:mm:ss') : null),
        sortable: false,
        class: ['text-caption', 'greyBuoyant'],
        cellClass: 'greyBuoyant'
      },
      {
        text: 'Status',
        value: 'state',
        sortable: false,
        class: ['text-caption', 'greyBuoyant'],
        cellClass: 'greyBuoyant'
      },
      {
        text: 'Duration',
        value: 'duration',
        sortable: false,
        class: ['text-caption', 'greyBuoyant'],
        cellClass: 'greyBuoyant'
      },
      {
        text: 'Action',
        value: 'action',
        sortable: false,
        class: ['text-caption', 'greyBuoyant'],
        cellClass: 'greyBuoyant'
      }
    ]
  }),
  computed: {
    ...mapState('components', [
      'rightPopUpMyContacts',
      'liveCalls'
    ]),
    liveCallsCount () {
      return this.liveCalls.length
    },
    callParticipantPopUp: {
      get () {
        return this.rightPopUpMyContacts
      },
      set (videoValue) {
        return videoValue
      }
    },
    countPage () {
      return Math.ceil(this.calls.total / this.take)
    }
  },
  watch: {
    page () {
      this.reloadCalls()
    },
    liveCallsCount (newValue, oldValue) {
      if (newValue < oldValue) {
        this.reloadCalls(0, (this.page || 1) * this.take)
      }
    }
  },
  created () {
    this.reloadCalls()
  },
  methods: {
    ...mapActions(['getLiveCalls']),
    ...mapMutations({
      resetLiveCalls: 'components/resetLiveCalls'
    }),
    stateFormatter (state) {
      switch (state) {
        case 'init':
          return 'Calling'
        case 'ringing':
          return 'Ringing'
        case 'live':
        case 'hold':
          return 'In Progress'
        case 'destroyed':
        case 'offline':
          return 'Ended'
        default:
          return '-'
      }
    },
    async loadMore () {
      this.nextPage(this.page + 1)
    },
    async reloadCalls (_skip = null, _take = null) {
      try {
        this.loading = true

        const { data } = await this.getLiveCalls({
          skip: _skip ?? (this.page - 1) * this.take,
          take: _take ?? this.take
        })

        this.resetLiveCalls(data.payload.items)
      } catch (e) {
        throw new Error(e)
      } finally {
        this.loading = false
      }
    },
    pagination (index) {
      this.page = index
    },
    previousPage (page) {
      if (page > 1) {
        this.page = page - 1
      }
    },
    nextPage (page) {
      if (page < this.countPage) {
        this.page = page + 1
      }
    }
  }
}
</script>
